import React from 'react';

const AboutUs = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      <HeaderSection />
      <div className="container mx-auto p-6 md:p-10">
        <ContentSection />
      </div>
    </div>
  );
};

const HeaderSection = () => (
  <div className="bg-[#034F75] text-white p-6 md:p-10 shadow-lg">
    <h1 className="text-2xl md:text-4xl font-bold text-center">About Conferencealerts.in</h1>
  </div>
);

const ContentSection = () => (
  <div className="bg-white p-6 md:p-10 rounded-lg shadow-md">
    <Paragraph text="Conferencealerts.in is India’s Number One online platform where people can search and stay informed about the upcoming International, National Conferences, online conferences, webinars, scientific and business seminars, and workshops. Currently, we have over 6 million event listings and have generated over 50 million replies and enquiries. Over 50 thousand potential participants and organizers visit our website daily, and over 100 thousand active engagements over our social media and email notifications per month." />
    <Paragraph text="Our efforts are being recognized by Reputed Organizations and referred by many scholars, students, academicians, renowned professors, scientists, and industrial personnel over the years. We have been fortunate to have some of the World’s best-renowned conference organizing partners with us on our journey." />
    <Paragraph text="We cover all Industrial, Academics and Research Conferences and events related to Engineering, Technology, General Science, Medical Science, Business Management, Social Science, etc. Being one of the best Conference alert portals, we emphasize the best quality conferences by the best conference organizers and allow listing the upcoming conferences in India and across the world." />
    <Paragraph text="Promote your Upcoming conferences, seminars, events, webinars, and workshops with us." isBold />
    <Paragraph text="Helping the potential Conference and event organizers, our dedicated team continuously works to make your event super successful. We help the organizers to reach the maximum people to get the maximum audience and participants." />
    <Paragraph text="Search Upcoming conference and events in all parts of India" isBold />
    <Paragraph text="With us, you can find the upcoming conference and events in all states of India. Reputed Societies, Organizations, Colleges, and Universities from all parts of India can list their events with us. We cover all major cities in India like Mumbai, Delhi, Chennai, Kolkata, Bangalore, Pune, Hyderabad, Guwahati, etc." />
    <Paragraph text="Start searching for a conference in India and participate in the easiest way on " linkText="www.conferencealerts.in" linkUrl="http://www.conferencealerts.in" />
  </div>
);

const Paragraph = ({ text, isBold, linkText, linkUrl }) => (
  <p className={`text-base md:text-lg mb-6 ${isBold ? 'font-semibold' : 'font-normal'} text-gray-800`}>
    {text}
    {linkText && linkUrl && (
      <a href={linkUrl} className="text-blue-600 hover:underline">
        {linkText}
      </a>
    )}
  </p>
);

export default AboutUs;
