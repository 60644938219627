import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Import marker icons
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Fix for the default marker icon issue
let DefaultIcon = L.icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const ContactUs = () => {
  const position = [20.2961, 85.8245]; // Coordinates for Bhubaneswar, Odisha

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      <div className="container mx-auto p-6 md:p-12 flex-grow">
        <Header />
        <div className="flex flex-col md:flex-row md:space-x-8 mt-8">
          <ContactDetails />
          <MapSection position={position} />
        </div>
        <SupportSection />
      </div>
    </div>
  );
};

const Header = () => (
  <div className="bg-[#034F75] text-white p-6 rounded-lg shadow-lg">
    <h1 className="text-3xl font-bold text-center">Conference Alerts India</h1>
    <Address />
  </div>
);

const Address = () => (
  <div className="mt-4 text-center">
    <p className="text-lg">Corporate office: 1056/B1, LANE 5, ITER College Rd,</p>
    <p className="text-lg">Jagmohan Nagar, Bhubaneswar,</p>
    <p className="text-lg">Odisha 751030</p>
  </div>
);

const ContactDetails = () => (
  <div className="md:w-1/2 mb-6 md:mb-0">
    <ContactOption
      title="Looking to add/list your conferences/events with us?"
      linkText="Go to Add New Events"
      linkUrl="/add-event"
    />
    <ContactOption
      title="Looking for updates on upcoming conferences and events?"
      linkText="Go to Subscribe here"
      linkUrl="/subscribe"
    />
    <GetInTouch />
  </div>
);

const ContactOption = ({ title, linkText, linkUrl }) => (
  <div className="mb-6">
    <h2 className="text-2xl font-semibold mb-3 text-[#034F75]">{title}</h2>
    <a href={linkUrl} className="text-lg text-orange-400 hover:underline">
      {linkText}
    </a>
  </div>
);

const GetInTouch = () => (
  <div>
    <h2 className="text-2xl font-semibold mt-6 mb-3 text-[#034F75]">Get in Touch</h2>
    <p className="text-lg mb-4 text-gray-800">
      Whether you're a conference/event seeker or an event organizer, we will be happy to help you with all your queries. Please email us to help you and resolve your query.
    </p>
  </div>
);

const MapSection = ({ position }) => (
  <div className="md:w-1/2">
    <MapContainer center={position} zoom={13} className="h-64 w-full rounded-lg shadow-md">
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position}>
        <Popup>Conference Alerts India</Popup>
      </Marker>
    </MapContainer>
  </div>
);

const SupportSection = () => (
  <div className="mt-8 bg-white p-6 rounded-lg shadow-lg">
    <h2 className="text-2xl font-semibold mb-3 text-[#034F75]">Support</h2>
    <p className="text-lg text-gray-800">
      Email:{' '}
      <a href="mailto:info@conferencealerts.in" className="text-orange-400 hover:underline">
        info@conferencealerts.in
      </a>
    </p>
  </div>
);

export default ContactUs;
