import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FiSearch, FiCalendar, FiMapPin } from 'react-icons/fi';

export default function ResultsScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const { events } = location.state || { events: [] };

  const [searchResults, setSearchResults] = useState(events);
  const [countries, setCountries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [topics, setTopics] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCategoryID, setSelectedCategoryID] = useState('');
  const [selectedTopic, setSelectedTopic] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/countries`)
      .then(response => setCountries(response.data))
      .catch(error => console.error('Error fetching countries:', error));

    axios.get(`${process.env.REACT_APP_API_URL}/event_cat`)
      .then(response => setCategories(response.data))
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

  useEffect(() => {
    if (!selectedCategoryID) return;
    axios.get(`${process.env.REACT_APP_API_URL}/event_cat/${selectedCategoryID}/event-topics`)
      .then(response => setTopics(response.data))
      .catch(error => console.error('Error fetching topics:', error));
  }, [selectedCategoryID]);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchResults([]);
    setCurrentPage(1); // Reset to the first page on new search

    const params = new URLSearchParams();
    if (selectedCountry) params.append('country', selectedCountry);
    if (selectedCategory) params.append('category', selectedCategory);
    if (selectedTopic) params.append('topic', selectedTopic);

    if (!params.toString()) {
      alert('Please select at least one search criteria.');
      return;
    }

    axios.get(`${process.env.REACT_APP_API_URL}/search?${params.toString()}`)
      .then(response => {
        const sortedEvents = response.data.sort((a, b) => new Date(a.event_stat) - new Date(b.event_stat));
        setSearchResults(sortedEvents);
      })
      .catch(error => console.error('Error performing search:', error));
  };

  const handleEventClick = (event) => {
    navigate('/event-details', { state: { event } });
  };

  const indexOfLastEvent = currentPage * itemsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - itemsPerPage;
  const currentEvents = searchResults.slice(indexOfFirstEvent, indexOfLastEvent);
  const totalPages = Math.ceil(searchResults.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  return (
    <div className="container mx-auto p-4 min-h-[80vh]">
      <h1 className="text-3xl font-bold text-center mb-8">Search Results</h1>

      <form className="flex items-end justify-center gap-4 flex-wrap mb-4" onSubmit={handleSearch}>
        <select
          className="bg-white px-2 py-3 w-48 font-bold text-sm text-black rounded-md ring-1 ring-gray-300 focus:ring-2 focus:outline-none focus:ring-orange-500"
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
        >
          <option value="">Choose a Country</option>
          {countries.map(country => (
            <option key={country.id} value={country.country}>
              {country.country}
            </option>
          ))}
        </select>
        <select
          className="bg-white px-2 py-3 w-48 font-bold text-sm text-black rounded-md ring-1 ring-gray-300 focus:ring-2 focus:outline-none focus:ring-orange-500"
          value={selectedCategory}
          onChange={(e) => {
            setSelectedCategory(e.target.value);
            const selectedCategoryObj = categories.find(category => category.category === e.target.value);
            setSelectedCategoryID(selectedCategoryObj.id);
          }}
        >
          <option value="">Choose a Category</option>
          {categories.map(category => (
            <option key={category.id} value={category.category}>
              {category.category}
            </option>
          ))}
        </select>
        <select
          className="bg-white px-2 py-3 w-48 font-bold text-sm text-black rounded-md ring-1 ring-gray-300 focus:ring-2 focus:outline-none focus:ring-orange-500"
          value={selectedTopic}
          onChange={(e) => setSelectedTopic(e.target.value)}
        >
          <option value="">Choose a Topic</option>
          {topics.map(topic => (
            <option key={topic.topic_id} value={topic.topic}>
              {topic.topic}
            </option>
          ))}
        </select>
        <button className="bg-orange-500 px-2 py-3 w-24 flex items-center justify-around font-bold uppercase text-sm text-white hover:bg-orange-700 rounded-md">
          <FiSearch className="mr-2" /> Search
        </button>
      </form>

      {searchResults.length === 0 ? (
        <p className="text-center text-gray-500">No events found.</p>
      ) : (
        <>
          <ul className="list-none">
            {currentEvents.map(event => (
              <li
                key={event.event_id}
                className="border p-4 rounded shadow hover:shadow-lg transition-shadow duration-300 cursor-pointer mb-4"
                onClick={() => handleEventClick(event)}
              >
                <div className="flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-4">
                  <div className="flex items-center text-gray-600 space-x-2">
                    <FiCalendar className="text-orange-500" />
                    <span className="text-lg font-semibold">{new Date(event.event_stat).toLocaleDateString()}</span>
                  </div>
                  <div>
                    <h2 className="text-xl font-bold text-gray-800 mb-1">{event.event_name}</h2>
                    <p className="flex items-center text-gray-600">
                      <FiMapPin className="text-orange-500 mr-1" /> {event.city}, {event.country}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <div className="flex justify-center items-center mt-8">
            <button
              className={`px-4 py-2 mr-4 text-white font-bold rounded-md ${currentPage === 1 ? 'bg-gray-300' : 'bg-orange-500 hover:bg-orange-700'}`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-lg font-bold">{currentPage} of {totalPages}</span>
            <button
              className={`px-4 py-2 ml-4 text-white font-bold rounded-md ${currentPage === totalPages ? 'bg-gray-300' : 'bg-orange-500 hover:bg-orange-700'}`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
}
