// components/EventDetails.js
import React from 'react';
import { FaCalendarAlt, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

const EventDetails = () => {
  const location = useLocation();
  const { event } = location.state;

  return (
    <div className="container mx-auto p-6 mt-10 mb-10 min-h-[70vh]">
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="p-8">
          <EventHeader eventName={event.event_name} shortDesc={event.short_desc} />
          <EventInfo icon={FaCalendarAlt} label="Date" value={`${formatDate(event.event_stat)} - ${formatDate(event.event_end)}`} />
          <EventInfo icon={FaMapMarkerAlt} label="Location" value={`${event.city}, ${event.country.split('#')[1]}`} />
          <EventInfo icon={FaEnvelope} label="Contact" value={event.event_enq_email} />
          <EventInfo label="Organizer" value={event.org_society} />
          <EventWebsite url={event.web_url} />
        </div>
      </div>
    </div>
  );
};

const EventHeader = ({ eventName, shortDesc }) => (
  <div className="mb-6">
    <h2 className="text-4xl font-semibold text-gray-900 mb-4">{eventName}</h2>
    <p className="text-xl text-gray-600">{shortDesc}</p>
  </div>
);

const EventInfo = ({ icon: Icon, label, value }) => (
  <div className="flex items-center text-gray-700 mb-4">
    {Icon && <Icon className="mr-3 text-blue-500" />}
    <span className="font-medium">{label}:</span>
    <span className="ml-2">{value}</span>
  </div>
);

const EventWebsite = ({ url }) => (
  <div className="mt-6">
    <a href={url} className="text-blue-600 font-medium hover:underline">
      Visit Event Website
    </a>
  </div>
);

const formatDate = (dateString) => new Date(dateString).toLocaleDateString();

export default EventDetails;
