import React from 'react';

const About = () => {
  return (
    <div className="container mx-auto my-12 p-6 sm:p-8 lg:p-12 bg-white rounded-lg shadow-lg">
      <Title text="International Conferences 2024" />
      <Description />
    </div>
  );
};

const Title = ({ text }) => (
  <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-6 text-center text-[#034F75]">
    {text}
  </h1>
);

const Description = () => (
  <p className="text-gray-800 text-sm sm:text-base lg:text-lg leading-relaxed">
    International Conferences 2024 - Here the professionals can stay updated with the latest academic journals and webinars happening worldwide. Our support provides consistent follow-ups to the professionals associated with us. Through continuous interactions via webinars and journals, we keep our associates informed about international conferences in their respective domains. We organize conferences across various domains such as business, science, education, etc., ensuring that participants gain valuable knowledge. Our goal is to streamline the process for professionals and students to attend international conferences, webinars, seminars, and other academic events in over 135 countries across more than 100 academic subjects. Become a subscriber today and receive free email alerts for upcoming conferences in your domain and location.
  </p>
);

export default About;
